/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "eu-west-2",
    "aws_cloud_logic_custom": [
        {
            "name": "AdminApi",
            "endpoint": "https://xbr6h8zjji.execute-api.eu-west-2.amazonaws.com/prod",
            "region": "eu-west-2"
        },
        {
            "name": "AdminQueries",
            "endpoint": "https://oy6lbag2wd.execute-api.eu-west-2.amazonaws.com/prod",
            "region": "eu-west-2"
        },
        {
            "name": "PrivateApi",
            "endpoint": "https://29hw7j330e.execute-api.eu-west-2.amazonaws.com/prod",
            "region": "eu-west-2"
        },
        {
            "name": "PublicApi",
            "endpoint": "https://dcllp1u081.execute-api.eu-west-2.amazonaws.com/prod",
            "region": "eu-west-2"
        }
    ],
    "aws_cognito_identity_pool_id": "eu-west-2:7f069c66-a67d-493a-8255-479c9475db1f",
    "aws_cognito_region": "eu-west-2",
    "aws_user_pools_id": "eu-west-2_lNQX5HSeC",
    "aws_user_pools_web_client_id": "6d89ljdujcqqmhql135a5ill90",
    "oauth": {},
    "aws_cognito_username_attributes": [
        "EMAIL"
    ],
    "aws_cognito_social_providers": [],
    "aws_cognito_signup_attributes": [
        "EMAIL",
        "NAME"
    ],
    "aws_cognito_mfa_configuration": "OFF",
    "aws_cognito_mfa_types": [
        "SMS"
    ],
    "aws_cognito_password_protection_settings": {
        "passwordPolicyMinLength": 8,
        "passwordPolicyCharacters": []
    },
    "aws_cognito_verification_mechanisms": [
        "EMAIL"
    ],
    "aws_dynamodb_all_tables_region": "eu-west-2",
    "aws_dynamodb_table_schemas": [
        {
            "tableName": "11thcloudvideos-prod",
            "region": "eu-west-2"
        },
        {
            "tableName": "videos-prod",
            "region": "eu-west-2"
        }
    ]
};


export default awsmobile;
